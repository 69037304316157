import { Carousel } from "antd";
import { useEffect, useState } from "react";

export const Reviews = () => {
  const reviews = Array.from({ length: 27 }, (_, index) => ({
    imageUrl: `/reviews/${index + 1}.jpg`,
  }));

  const contentStyle: React.CSSProperties = {
    margin: 0,
    height: "600px",
    color: "#fff",
    justifyContent: "center",
  };

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="bg-[#c5c5c5]">
      {/* Variable 3 */}
      <div className="flex items-center justify-center">
        <h1 className="text-[#000] text-[45px] font-normal mt-10">Відгуки</h1>
      </div>
      {windowWidth > 500 ? (
        <Carousel autoplay infinite fade dots={true}>
          {reviews.map((review, index) =>
            index % 3 === 0 ? (
              <div key={index}>
                <div className="flex gap-8 justify-center items-center">
                  {reviews.slice(index, index + 3).map((review, innerIndex) => (
                    <div
                      key={innerIndex}
                      className="flex items-center relative lg:max-w-[1300px] max-w-[370px] h-[600px]"
                    >
                      <div className="scale-95 hover:scale-105 transition-all transform duration-500 border-2 w-[420px] h-[520px] rounded-[30px] overflow-hidden bg-[#fff] shadow-lg flex justify-center items-center">
                        <img
                          src={review.imageUrl}
                          alt={`Review ${innerIndex + 1}`}
                          className="object-cover rounded-lg"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null
          )}
        </Carousel>
      ) : (
        <Carousel>
          {reviews.map((review, innerIndex) => (
            <div className="mt-10 mb-14 p-1">
              <img
                style={contentStyle}
                className="rounded-md object-cover w-full"
                src={review.imageUrl}
                alt={`Review ${innerIndex + 1}`}
              />
            </div>
          ))}
        </Carousel>
      )}
    </section>
  );
};
