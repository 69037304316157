import { Form, Input, Select, Checkbox, Button, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { isFeedBackVisibleValueState } from "../../recoil/selector/isFeedBackVisible";
import Close from "./../../images/close.svg";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import properties from "../../const/sortProperties.json";
import "./style.css";

const { Option } = Select;

// Modal interface
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}
//

const ModalWindow: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  // Form reset
  const [form] = Form.useForm();

  const isVisible = useRecoilValue(isFeedBackVisibleValueState);

  const resetForm = () => {
    form.resetFields();
  };
  //

  const [prices, setPrices] = useState<
    {
      id: number;
      question: string;
      consultation_type: string;
      description: string;
    }[]
  >([]);

  const [consultationType, setConsultationType] = useState<string | null>(null);
  const [consultationCategory, setConsultationCategory] = useState<
    string | null
  >(null);

  const handleConsultationTypeChange = (value: string) => {
    setConsultationType(value);
    setConsultationCategory("");
  };

  const handleConsultationCategoryChange = (value: string) => {
    setConsultationCategory(value);
  };

  // Викликаємо функцію для виконання запиту
  useEffect(() => {
    fetchData();
  }, []);

  //
  const getResult = (): string[] => {
    if (prices) {
      const item = prices.find(
        (el) =>
          el.question === consultationType &&
          el.consultation_type === consultationCategory
      );
      if (item && item.description) {
        return item.description.split("/n");
      }
    }
    return [];
  };
  //

  // Notif massege
  const [api, contextHolder] = notification.useNotification();

  const openSuccessNotification = (title: string, description: string) => {
    api.success({
      message: "Успіх !",
      description: "Ви успішно надіслали форму. Очікуйте на відповідь.",
    });
  };

  const openErrorNotification = (title: string, description: string) => {
    api.error({
      message: "Помилка",
      description: "Виникла помилка. Спробуйте будь ласка пізніше!",
    });
  };
  //

  // Close Modal
  const handleCloseModal = () => {
    onClose();
  };
  //

  // POST
  const onFinish = async (values: any) => {
    try {
      const sanitizedValues = sanitizeValues(values);
      // console.log("Надіслано на сервер:", sanitizedValues);
      const response = await axios.post(
        "https://back.advokatpushkarenko.com.ua/api/v2/feedback/",
        // "",
        sanitizedValues,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // console.log("Відповідь сервера:", response.data);
        openSuccessNotification("success", "Success");
        setTimeout(() => {
          handleCloseModal();
        }, 3000);
      } else {
        // console.error(
        //   "Серверна помилка:",
        //   response.status,
        //   response.statusText
        // );
        openErrorNotification("error", `Error: ${response.statusText}`);
      }
    } catch (error: any) {
      // console.error("Помилка під час запиту:", error);
      openErrorNotification("error", `Error: ${error.message}`);
    }
  };
  //

  // Sanitize undefined === null
  const sanitizeValues = (obj: any): any => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === undefined) {
        obj[key] = null;
      }
    }
    return obj;
  };
  //

  // GET
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://back.advokatpushkarenko.com.ua/api/v2/prices/"
      );
      const responseData = response.data;
      setPrices(responseData.price_list);
      // console.log("Response Data:", responseData);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  //
  // Validate
  // Valid number
  const validatePhoneNumber = (_: any, value: string) => {
    const regex = /^(\+?\d{9,12}|\d{9,12})$/;
    if (!regex.test(value)) {
      return Promise.reject("Будь ласка, введіть коректний номер телефону.");
    }

    return Promise.resolve();
  };

  //
  // Valid Email
  const validateEmail = (_: any, value: string) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!regex.test(value)) {
      return Promise.reject("Будь ласка, введіть коректну електронну пошту.");
    }
    return Promise.resolve();
  };
  //
  //

  const getQuestionType = (): string[] => {
    const setCollection = new Set<string>();
    
    // Збираємо унікальні значення питань у Set
    prices
      .map((el) => el.question)
      .forEach((element) => {
        setCollection.add(element);
      });
    
    // Перетворюємо Set у масив
    const types: string[] = Array.from(setCollection);
    
    const finded: string[] = [];
    const noFinded: string[] = [];

    // Проходимо по категоріям і зберігаємо знайдені у порядку їх появи
    for (let item of properties.category) {
      if (types.includes(item)) {
        finded.push(item);
      }
    }

    // Зберігаємо ті типи, яких немає в категоріях, у тому порядку, в якому вони записані
    noFinded.push(...types.filter((el) => !finded.includes(el)));

    // Повертаємо спочатку знайдені, а потім не знайдені типи
    return [...finded, ...noFinded];
};

  const getCategories = (): string[] => {
    if (!consultationType) {
      return [];
    }
    const category: string[] = prices
      .filter((el) => el.question === consultationType)
      .map((el) => el.consultation_type);

    const finded: string[] = [];
    const noFinded: string[] = [];

    for (let item of properties.type) {
      if (category.find((el) => el === item)) {
        finded.push(item);
      }
    }

    noFinded.push(...category.filter((el) => !finded.includes(el)));

    return [...finded, ...noFinded];
  };
  const [consultationTypeSelected, setConsultationTypeSelected] =
    useState(false);

  // console.log(consultationType, consultationCategory, getCategories());

  return isVisible ? (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-[rgba(0,0,0,0.40)] z-[9999999999]">
      <div
        id="scroll"
        className="max-w-[750px] w-full lg:min-h-[667px] min-h-screen bg-[#ddd] lg:rounded-[30px] text-black z-40 overflow-y-auto"
      >
        {contextHolder}
        <div className="flex justify-end items-center relative">
          <button
            className="text-white"
            onClick={() => {
              resetForm();
              handleCloseModal();
            }}
          >
            <img
              src={Close}
              alt="Close"
              className="w-[30px] absolute right-[25px] top-[24px] hover:scale-105 transition-all transform duration-300"
            />
          </button>
        </div>
        <div className="flex flex-col justify-center pt-[50px] lg:p-[50px] p-[15px] mt-[10px] overflow-x-hidden">
          <div className="flex justify-center">
            <h1 className="text-[24px] pb-[25px]">Запис на консультацію</h1>
          </div>
          <Form
            form={form}
            onFinish={(val: any) => {
              onFinish(val);
            }}
            labelCol={{ span: 8 }}
            style={{ maxWidth: "750px" }}
          >
            <div className="flex w-full justify-between">
              <Form.Item
                name="first_name"
                hasFeedback
                rules={[
                  { required: true, message: "Будь ласка, введіть ваше ім'я!" },
                ]}
                className="w-[48%]"
              >
                <Input placeholder="Ім'я" />
              </Form.Item>

              <Form.Item
                name="last_name"
                rules={[
                  {
                    message: "Будь ласка, введіть ваше прізвище!",
                  },
                ]}
                className="w-[48%]"
              >
                <Input placeholder="Прізвище" />
              </Form.Item>
            </div>

            <div className="w-full flex justify-between">
              <Form.Item
                name="phone_number"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: validatePhoneNumber,
                  },
                ]}
                className="w-[48%]"
              >
                <Input placeholder="Номер телефону" />
              </Form.Item>

              <Form.Item
                name="email"
                hasFeedback
                rules={[
                  {
                    message: "Будь ласка, введіть вашу поштову скриньку!",
                  },
                  {
                    validator: validateEmail,
                  },
                ]}
                className="w-[48%]"
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
            <Form.Item
              name="question"
              rules={[
                {
                  required: true,
                  message: "Будь ласка, оберіть питання для консультації!",
                },
              ]}
            >
              <Select
                value={consultationType}
                onChange={(value) => {
                  handleConsultationTypeChange(value);
                  setConsultationTypeSelected(true);
                  // Очищаємо значення другого поля при виборі значення у першому полі
                  form.setFieldsValue({
                    consultation_type: undefined,
                  });
                }}
                placeholder="Оберіть з якого питання необхідна правова консультація"
                className="text-nowrap"
              >
                {getQuestionType().map((el) => (
                  <Option key={el} value={el}>
                    {el}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="consultation_type"
              // hasFeedback
              rules={[
                {
                  required: true,
                  message: "Будь ласка, оберіть вид консультації!",
                },
              ]}
            >
              <Select
                value={consultationCategory}
                placeholder="Вид консультації"
                disabled={!consultationTypeSelected}
                onChange={(value) => handleConsultationCategoryChange(value)}
              >
                {getCategories().map((el) => (
                  <Option value={el} key={el}>
                    {el}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {getResult().length > 0 && (
              <span className="flex flex-col gap-1 justify-center mt-4 bg-[#ddd] border border-[#fff] p-[10px] rounded-[14px] text-[#000] mb-4">
                {getResult().map((el, index) => (
                  <div key={index}>{el}</div>
                ))}
              </span>
            )}

            <Form.Item
              name="description"
              rules={[
                {
                  message: "Будь ласка, введіть деталі вашої ситуації!",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={10000}
                placeholder="Опишіть Вашу ситуацію та питання більш детально"
                style={{ height: 85, resize: "none" }}
              />
            </Form.Item>

            <Form.Item
              name="recaptcha_token"
              className="flex items-center justify-center"
              rules={[
                {
                  required: true,
                  message: "Необхідно пройти ReCAPTCHA !",
                },
              ]}
            >
              <ReCAPTCHA sitekey="6LeIhzgpAAAAABX0hx4BR7fbZxqHhxmezLPPKTvE" />
            </Form.Item>

            <Form.Item
              name="agree"
              hasFeedback
              valuePropName="checked"
              wrapperCol={{ offset: 4 }}
              className="flex justify-center items-center"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject("Підтвердіть згоду із умовами!"),
                },
              ]}
            >
              <Checkbox className="w-[400px] flex items-center">
                Для продовження, підтвердіть <br />
                згоду із{" "}
                <a href="/offer" target="blank" className="text-blue-500">
                  зазначеними умовами
                </a>
              </Checkbox>
            </Form.Item>

            <Form.Item className="flex justify-center">
              <Button
                type="primary"
                htmlType="submit"
                className="bg-[#fff] text-[#000] hover:!bg-[#000] hover:!text-[#fff] font-medium h-10 w-[100px] rounded-[35px] transition-all transform duration-500 shadow-md "
              >
                Надіслати
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  ) : null;
};

export default ModalWindow;
