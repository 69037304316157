import { useRecoilState } from "recoil";
import { isFeedBackVisibleState } from "../../recoil/atom/isFeedBackVisible";
import { useEffect, useState } from "react";

export const BottomButtonMenu: React.FC = () => {
  const [, setIsVisible] = useRecoilState(isFeedBackVisibleState);
  const openModal = () => {
    setIsVisible(true);
  };

  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 300;

      setIsButtonVisible(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      {isButtonVisible && (
        <div className="w-11/12 h-14 flex justify-center opacity-[0.98] bg-[#1F3645] font-semibold text-[22px] fixed bottom-[12px] right-[18px] z-30 rounded-[24px] lg:hidden">
          <button
            onClick={openModal}
            className="w-full h-14 rounded-[20px_0_0_0] text-white animate-pulse"
          >
            Умови та запис на консультацію
          </button>
        </div>
      )}
    </div>
  );
};
