import Team_2 from "./../../images/team-2.webp";
import Team_1 from "./../../images/team-1.webp";

const MyTeam = () => {
  return (
    <div className="bg-[#C5C5C5]">
      <section className="my-10">
        <div className="flex flex-col justify-center items-center">
          <div>
            <h1 className="text-[45px]  mb-10" id="teams">
              Моя Команда
            </h1>
          </div>
          <div className="flex items-center lg:flex-row flex-col justify-center max-w-[1100px] w-full">
            <div className="max-w-[595px] w-full text-[#000] font-raleway lg:w-[595px] py-[15px]  lg:text-[20px] font-light lg:leading-[38px] p-[20px]">
              <p>
                Ми – активна та прогресивна команда. Наші підходи в роботі
                надсучасні і креативні, а стосунки з нашими клієнтами вони
                назавжди, адже отримавши нашу допомогу вперше, ви не зможете
                залишитись байдужим.
              </p>
              <p className="mt-5">
                Постійний розвиток і навчання – є цінностями нашого ком’юніті, а
                швидка і якісна правова допомога – є нашою спільною ціллю.
              </p>
            </div>
            <div className="flex items-center justify-center max-w-[481px] w-full lg:gap-0 gap-[10px]">
              <div className="lg:pl-2">
                <img
                  className="lg:w-[440px] w-[230px] lg:h-[440px] h-[325px] object-cover lg:object-[32%_35%] object-[60%_35%] rounded-[30px]"
                  src={Team_2}
                  alt="MyTeamPushkarenko"
                />
              </div>
              <div className="lg:pl-4">
                <img
                  className="lg:w-[340px] w-[170px] lg:h-[340px] h-[260px] object-cover lg:object-[32%_35%] object-[60%_35%] rounded-[30px]"
                  src={Team_1}
                  alt="MyTeamPushkarenko"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyTeam;
